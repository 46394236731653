<template>
  <div>
    <!-- Mobile layout -->
    <responsive-layout
      v-if="$vuetify.breakpoint.mobile"
      poster="https://vod.eventcdn.net/pandora/smc2021/Lounge_Bg.png"
    >
      <v-sheet
        style="min-height: 100vh"
        class="d-flex flex-column justify-center transparent"
        dark
      >
        <div class="main py-8 flex-fill">
          <v-container fluid>
            <!-- 'Breadcrumb' navigation at the top -->
            <v-row justify="start" align="start" class="mt-5 lobby-router-row">
              <v-col
                cols="12"
                class="pl-0 d-flex align-start justify-start flex-column ml-1"
              >
                <!--<v-btn
                  class="ma-2 white--text lobby-back-button rounded-0 mr-0 mb-0"
                  color="black"
                >
                  <router-link class="white--text" to="/lobby">
                    <v-icon left dark color="pink" class="back-arrow-theatre">
                      fas fa-chevron-left
                    </v-icon>
                    <span class="back-arrow-lobby-link">To lobby</span>
                  </router-link>
                </v-btn>-->
                <AppBackButton class="ml-2" :to="{ name: 'Lobby' }"
                  >TO LOBBY</AppBackButton
                >
                <div class="route-name-background-container-information ml-2">
                  <span class="theatre-route-name">{{ $route.meta.name }}</span>
                </div>
              </v-col>
            </v-row>

            <!-- Downloads section -->
            <v-row
              justify="start"
              align="start"
              class="mt-5 sessions-agenda-lobby-row"
            >
              <v-col cols="12">
                <!-- Meet your colleagues image section -->
                <v-col
                  cols="12"
                  class="
                    pb-0
                    pr-0
                    pl-0
                    mt-4
                    meet-your-colleagues-image-container
                  "
                >
                  <div class="py-9 d-flex pl-2 pr-4">
                    <v-col cols="12" class="pl-8 pr-8">
                      <v-col
                        cols="12"
                        class="mt-4 mb-4 d-flex align-center flex-wrap flex-row"
                      >
                        <v-col
                          cols="12"
                          align="center"
                          class="d-flex mb-6 justify-center"
                        >
                          <h2
                            class="
                              white--text
                              text-center
                              meet-your-colleagues-headline
                            "
                          >
                            Meet your Colleagues
                          </h2>
                        </v-col>
                        <v-col
                          cols="12"
                          align="center"
                          class="d-flex justify-center"
                        >
                          <v-btn color="pink" class="rounded-0" depressed>
                            <router-link
                              class="black--text pandora-man-link"
                              to="/meet-your-colleagues"
                              >Go to room</router-link
                            >
                          </v-btn>
                        </v-col>
                      </v-col>
                    </v-col>
                  </div>
                </v-col>

                <!-- Leaderboard top 100 section -->

                <v-col cols="12" class="pb-0 pr-0 pl-0 mt-4">
                  <div
                    class="pl-6 py-3 black text-left white--text d-flex"
                    style="font-size: 1.2rem"
                  >
                    <h3 class="pa-4 white--text">LEADERBOARD - TOP 100</h3>
                    <v-icon
                      dark
                      left
                      color="pink"
                      @click="dialogFirst = !false"
                      class="info-overlay-icon"
                    >
                      fas fa-info-circle
                    </v-icon>
                  </div>

                  <div class="py-6 d-flex white pl-6 pr-4">
                    <v-row class="pl-8 pr-8">
                      <v-col
                        v-if="event.state === 'ONDEMAND'"
                        cols="12"
                        class="
                          agenda-container
                          mt-3
                          mb-3
                          d-flex
                          align-center
                          pa-0
                          white
                        "
                      >
                        <p class="text-left black--text">
                          Thank you for participating and engaging in the 2021
                          Pandora Dares Store Manager Conference. As the
                          conference has now ended, the Leader Board is now
                          closed. Winners will be announced soon!
                        </p>
                      </v-col>
                      <v-col
                        v-for="score in pointLeaderboard"
                        :key="score.id"
                        cols="12"
                        class="
                          agenda-container
                          mt-3
                          mb-3
                          d-flex
                          align-center
                          pa-0
                          white
                        "
                      >
                        <v-col
                          cols="12"
                          class="d-flex mr-1 pa-0"
                          style="
                            background: var(--v-lighter-grey-base) 0% 0%
                              no-repeat padding-box;
                            border-left: 3px solid var(--v-pink-base) !important;
                          "
                        >
                          <v-col cols="10" class="d-flex align-center">
                            <p class="downloads-title mb-0">
                              {{ score.user }}
                            </p>
                          </v-col>
                          <v-col
                            cols="2"
                            class="
                              d-flex
                              align-center
                              justify-center
                              total-points-container
                            "
                          >
                            <span class="text-center white--text">{{
                              score.points
                            }}</span>
                          </v-col>
                        </v-col>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>

                <!-- Third hidden dialog/question box -->
                <v-col cols="12">
                  <v-row justify="center">
                    <v-dialog
                      v-model="dialogFirst"
                      max-width="380"
                      content-class="secret-question-dialog-lounge-page"
                      overlay-opacity="0"
                    >
                      <v-card>
                        <v-card-title
                          class="
                            gain-points-title
                            pink--text
                            d-flex
                            align-center
                            justify-space-between
                          "
                        >
                          <div class="d-flex align-center" style="gap: 1rem">
                            <v-img
                              style="height: 20px; width: 15px"
                              src="https://vod.eventcdn.net/pandora/smc2021/Pandora_Monogram.svg"
                            ></v-img>

                            How to gain points?
                          </div>

                          <v-icon
                            dark
                            color="pink"
                            @click="dialogFirst = false"
                            class="fullscreen-overlay-close"
                          >
                            fas fa-times-circle
                          </v-icon>
                        </v-card-title>

                        <v-col
                          cols="12"
                          class="d-flex flex-row flex-wrap text-left"
                        >
                          <v-col
                            cols="12"
                            class="gain-points-points"
                            v-if="event.state === 'ONDEMAND'"
                          >
                            It is no longer possible to gain points. <br />
                            Thank you for participating.
                          </v-col>
                          <v-col cols="3" class="gain-points-points">
                            50 pts.
                          </v-col>
                          <v-col cols="8" class="gain-points-explanation"
                            >Completion of User Profile</v-col
                          >
                          <v-col cols="3" class="gain-points-points">
                            75 pts.
                          </v-col>
                          <v-col cols="8" class="gain-points-explanation"
                            >General Session</v-col
                          >

                          <v-col cols="3" class="gain-points-points">
                            50 pts.
                          </v-col>
                          <v-col cols="8" class="gain-points-explanation"
                            >Workshops</v-col
                          >

                          <v-col cols="3" class="gain-points-points">
                            25 pts.
                          </v-col>
                          <v-col cols="8" class="gain-points-explanation"
                            >Pre-recorded Workshops</v-col
                          >

                          <v-col cols="3" class="gain-points-points">
                            10 pts.
                          </v-col>
                          <v-col cols="8" class="gain-points-explanation"
                            >Downloads</v-col
                          >

                          <v-col cols="3" class="gain-points-points">
                            25 pts.
                          </v-col>
                          <v-col cols="8" class="gain-points-explanation"
                            >Post session survey completion</v-col
                          >

                          <v-col cols="3" class="gain-points-points">
                            25 pts.
                          </v-col>
                          <v-col cols="8" class="gain-points-explanation"
                            >Visit destination in Tour de Pandora</v-col
                          >

                          <v-col cols="3" class="gain-points-points">
                            15 pts.
                          </v-col>
                          <v-col cols="8" class="gain-points-explanation"
                            >Correct answer to hidden questions in the
                            Rooms</v-col
                          >
                        </v-col>
                      </v-card>
                    </v-dialog>
                  </v-row>
                </v-col>

                <!-- Downloads section -->

                <v-col cols="12" class="pb-0 pr-0 pl-0 mt-4">
                  <div
                    class="pl-6 py-3 black text-left white--text"
                    style="font-size: 1.2rem"
                  >
                    <h3 class="pa-4 white--text">DOWNLOADS</h3>
                  </div>
                  <div class="py-6 d-flex white pl-2 pr-4">
                    <v-col cols="12" class="pl-8 pr-8">
                      <v-col
                        cols="12"
                        v-for="(download, idx) in downloads"
                        :key="idx"
                        class="
                          agenda-container
                          mt-4
                          mb-4
                          d-flex
                          align-center
                          pt-0
                          pb-0
                          pr-0
                        "
                      >
                        <v-col cols="6" class="d-flex pt-0 pb-0">
                          <v-icon left dark color="black" class="mr-3">
                            fas fa-file-pdf
                          </v-icon>
                          <p class="downloads-title mb-0">
                            {{ download.name }}
                          </p>
                        </v-col>
                        <v-col
                          cols="6"
                          class="d-flex align-center justify-end pr-0 pt-0 pb-0"
                        >
                          <p
                            class="downloads-points mb-0"
                            v-if="event.state !== 'ONDEMAND'"
                          >
                            {{ download.points }} pts.
                          </p>
                          <DownloadTracker
                            class="black py-2 px-4 ml-2"
                            :href="download.href"
                          >
                            <v-icon dark color="pink"> fas fa-download </v-icon>
                          </DownloadTracker>
                        </v-col>
                      </v-col>
                    </v-col>
                  </div>
                </v-col>

                <!-- Share your love section -->

                <v-col cols="12" class="pb-0 pr-0 pl-0 mt-4">
                  <div
                    class="pl-6 py-3 black text-left white--text"
                    style="font-size: 1.2rem"
                  >
                    <h3 class="pa-4 white--text">SHARE YOUR LOVE</h3>
                  </div>
                  <div class="py-6 d-flex white pr-4">
                    <v-col cols="12" class="pl-8 pr-8">
                      <h2 class="share-your-love-headline text-left pl-2">
                        Use
                        <b>#PANDORADARES</b>
                        to join the list
                      </h2>
                      <v-col
                        cols="12"
                        class="agenda-container d-flex white pl-2 pt-1"
                      >
                        <code>Instagram iframe embed</code>
                      </v-col>
                    </v-col>
                  </div>
                </v-col>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-sheet>
    </responsive-layout>

    <!-- 16:9 layout for desktop devices -->
    <aspect-ratio-layout
      v-else
      poster="https://vod.eventcdn.net/pandora/smc2021/backgrounds/Lounge_loop.jpg"
      background-video-initial="https://vod.eventcdn.net/pandora/smc2021/BackgroundVideo/Lounge.mp4"
      background-video-loop="https://vod.eventcdn.net/pandora/smc2021/BackgroundVideo/loop/Lounge_loop.mp4"
      #default="{ replayBackgroundVideo, isLooping }"
    >
      <v-sheet class="d-flex flex-column transparent" light>
        <div class="main">
          <template v-if="isLooping">
            <div style="position: fixed; right: 353px; top: 69%">
              <div style="position: relative" class="d-flex align-center">
                <v-btn
                  color="black"
                  class="play-btn pr-7"
                  style="position: unset"
                >
                  <span class="white--text play-btn-text">WELCOME</span>
                </v-btn>

                <v-btn
                  fab
                  class="rounded-circle ml-n4"
                  color="white"
                  @click="replayBackgroundVideo"
                  style="z-index: 16"
                >
                  <v-icon dark color="pink"> fas fa-play </v-icon>
                </v-btn>
              </div>
            </div>
          </template>
          <v-container fluid>
            <!-- 'Breadcrumb' navigation at the top -->
            <v-row justify="start" align="start" class="mt-5 lobby-router-row">
              <v-col
                cols="8"
                class="pl-0 d-flex align-start justify-start ml-10"
              >
                <!--<v-btn
                  class="ma-2 white--text lobby-back-button rounded-0 mr-0"
                  color="black"
                >
                  <router-link class="white--text" to="/lobby">
                    <v-icon left dark color="pink" class="back-arrow-theatre">
                      fas fa-chevron-left
                    </v-icon>
                    <span class="back-arrow-lobby-link">To lobby</span>
                  </router-link>
                </v-btn>-->
                <AppBackButton class="ml-2 mt-2" :to="{ name: 'Lobby' }"
                  >TO LOBBY</AppBackButton
                >
                <div
                  class="
                    route-name-background-container-meet-your-colleagues
                    mt-2
                  "
                >
                  <span class="theatre-route-name">{{ $route.meta.name }}</span>
                </div>
              </v-col>
            </v-row>

            <!-- Pandroaman image section -->
            <v-row
              justify="start"
              align="start"
              class="mt-5 sessions-agenda-lobby-row"
            >
              <v-col cols="4">
                <v-col
                  cols="12"
                  class="pb-0 pr-0 pl-0 mt-4 pandoraman-image-container"
                >
                  <div class="py-6 d-flex pl-2 pr-4">
                    <v-col cols="12" class="pl-8 pr-8">
                      <v-col
                        cols="12"
                        class="mt-4 mb-4 d-flex align-center flex-wrap flex-row"
                      >
                        <v-col
                          cols="12"
                          align="center"
                          justify="start"
                          class="d-flex mb-6"
                        >
                          <v-img
                            contain
                            height="62px"
                            src="https://vod.eventcdn.net/pandora/smc2021/pandoraman_logo%402x.png"
                          ></v-img>
                        </v-col>
                        <v-col
                          cols="12"
                          align="center"
                          class="d-flex justify-end"
                        >
                          <v-btn color="pink" class="rounded-0" depressed>
                            <router-link
                              class="black--text pandora-man-link"
                              to="/games/pandora-man"
                              >Start game</router-link
                            >
                          </v-btn>
                        </v-col>
                      </v-col>
                    </v-col>
                  </div>
                </v-col>

                <!-- Leaderboard top 100 section -->

                <v-col cols="12" class="pb-0 pr-0 pl-0 mt-4">
                  <div
                    class="pl-6 py-3 black text-left white--text d-flex"
                    style="font-size: 1.2rem"
                  >
                    <h3 class="pa-4 white--text">LEADERBOARD - TOP 100</h3>
                    <v-icon
                      dark
                      left
                      color="pink"
                      @click="dialogFirst = !false"
                      class="info-overlay-icon"
                    >
                      fas fa-info-circle
                    </v-icon>
                  </div>

                  <div class="py-6 d-flex white pl-6 pr-4">
                    <v-row class="pl-8 pr-8">
                      <v-col
                        v-if="event.state === 'ONDEMAND'"
                        cols="12"
                        class="
                          agenda-container
                          mt-3
                          mb-3
                          d-flex
                          align-center
                          pa-0
                          white
                        "
                      >
                        <p class="text-left">
                          Thank you for participating and engaging in the 2021
                          Pandora Dares Store Manager Conference. As the
                          conference has now ended, the Leader Board is now
                          closed. Winners will be announced soon!
                        </p>
                      </v-col>
                      <v-col
                        v-for="score in pointLeaderboard"
                        :key="score.id"
                        cols="12"
                        class="
                          agenda-container
                          mt-3
                          mb-3
                          d-flex
                          align-center
                          pa-0
                          white
                        "
                      >
                        <v-col
                          cols="12"
                          class="d-flex mr-1 pa-0"
                          style="
                            background: var(--v-lighter-grey-base) 0% 0%
                              no-repeat padding-box;
                            border-left: 3px solid var(--v-pink-base) !important;
                          "
                        >
                          <v-col cols="10" class="d-flex align-center">
                            <p class="downloads-title mb-0">
                              {{ score.user }}
                            </p>
                          </v-col>
                          <v-col
                            cols="2"
                            class="
                              d-flex
                              align-center
                              justify-center
                              total-points-container
                            "
                          >
                            <span class="text-center white--text">{{
                              score.points
                            }}</span>
                          </v-col>
                        </v-col>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>

                <!-- Third hidden dialog/question box -->
                <v-col cols="4">
                  <v-row justify="center">
                    <v-dialog
                      v-model="dialogFirst"
                      max-width="380"
                      content-class="secret-question-dialog-lounge-page"
                      overlay-opacity="0"
                    >
                      <v-card>
                        <v-card-title
                          class="
                            gain-points-title
                            pink--text
                            d-flex
                            align-center
                            justify-space-between
                          "
                        >
                          <div class="d-flex align-center" style="gap: 1rem">
                            <v-img
                              style="height: 20px; width: 15px"
                              src="https://vod.eventcdn.net/pandora/smc2021/Pandora_Monogram.svg"
                            ></v-img>

                            How to gain points?
                          </div>

                          <v-icon
                            dark
                            color="pink"
                            @click="dialogFirst = false"
                            class="fullscreen-overlay-close"
                          >
                            fas fa-times-circle
                          </v-icon>
                        </v-card-title>

                        <v-col
                          cols="12"
                          class="d-flex flex-row flex-wrap text-left"
                        >
                          <v-col
                            cols="12"
                            class="gain-points-points"
                            v-if="event.state === 'ONDEMAND'"
                          >
                            It is no longer possible to gain points. <br />
                            Thank you for participating.
                          </v-col>
                          <v-col cols="3" class="gain-points-points">
                            50 pts.
                          </v-col>
                          <v-col cols="8" class="gain-points-explanation"
                            >Completion of User Profile</v-col
                          >
                          <v-col cols="3" class="gain-points-points">
                            75 pts.
                          </v-col>
                          <v-col cols="8" class="gain-points-explanation"
                            >General Session</v-col
                          >

                          <v-col cols="3" class="gain-points-points">
                            50 pts.
                          </v-col>
                          <v-col cols="8" class="gain-points-explanation"
                            >Workshops</v-col
                          >

                          <v-col cols="3" class="gain-points-points">
                            25 pts.
                          </v-col>
                          <v-col cols="8" class="gain-points-explanation"
                            >Pre-recorded Workshops</v-col
                          >

                          <v-col cols="3" class="gain-points-points">
                            10 pts.
                          </v-col>
                          <v-col cols="8" class="gain-points-explanation"
                            >Downloads</v-col
                          >

                          <v-col cols="3" class="gain-points-points">
                            25 pts.
                          </v-col>
                          <v-col cols="8" class="gain-points-explanation"
                            >Post session survey completion</v-col
                          >

                          <v-col cols="3" class="gain-points-points">
                            25 pts.
                          </v-col>
                          <v-col cols="8" class="gain-points-explanation"
                            >Visit destination in Tour de Pandora</v-col
                          >

                          <v-col cols="3" class="gain-points-points">
                            15 pts.
                          </v-col>
                          <v-col cols="8" class="gain-points-explanation"
                            >Correct answer to hidden questions in the
                            Rooms</v-col
                          >
                        </v-col>
                      </v-card>
                    </v-dialog>
                  </v-row>
                </v-col>
              </v-col>

              <!-- Meet your colleagues image section -->

              <v-col cols="4">
                <v-col
                  cols="12"
                  class="
                    pb-0
                    pr-0
                    pl-0
                    mt-4
                    meet-your-colleagues-image-container
                  "
                >
                  <div class="py-9 d-flex pl-2 pr-4">
                    <v-col cols="12" class="pl-8 pr-8">
                      <v-col
                        cols="12"
                        class="mt-4 mb-4 d-flex align-center flex-wrap flex-row"
                      >
                        <v-col
                          cols="12"
                          align="center"
                          class="d-flex mb-6 justify-center"
                        >
                          <h2
                            class="
                              white--text
                              text-center
                              meet-your-colleagues-headline
                            "
                          >
                            Meet your Colleagues
                          </h2>
                        </v-col>
                        <v-col
                          cols="12"
                          align="center"
                          class="d-flex justify-center"
                        >
                          <v-btn color="pink" class="rounded-0" depressed>
                            <router-link
                              class="black--text pandora-man-link"
                              to="/meet-your-colleagues"
                              >Go to room</router-link
                            >
                          </v-btn>
                        </v-col>
                      </v-col>
                    </v-col>
                  </div>
                </v-col>

                <!-- Downloads section -->

                <v-col cols="12" class="pb-0 pr-0 pl-0 mt-4">
                  <div
                    class="pl-6 py-3 black text-left white--text"
                    style="font-size: 1.2rem"
                  >
                    <h3 class="pa-4 white--text">DOWNLOADS</h3>
                  </div>
                  <div class="py-6 d-flex white pl-2 pr-4">
                    <v-col cols="12" class="pl-8 pr-8">
                      <v-col
                        cols="12"
                        v-for="(download, idx) in downloads"
                        :key="idx"
                        class="
                          agenda-container
                          mt-4
                          mb-4
                          d-flex
                          align-center
                          pt-0
                          pb-0
                          pr-0
                        "
                      >
                        <v-col cols="6" class="d-flex pt-0 pb-0">
                          <v-icon left dark color="black" class="mr-3">
                            fas fa-file-pdf
                          </v-icon>
                          <p class="downloads-title mb-0">
                            {{ download.name }}
                          </p>
                        </v-col>
                        <v-col
                          cols="6"
                          class="d-flex align-center justify-end pr-0 pt-0 pb-0"
                        >
                          <p
                            class="downloads-points mb-0"
                            v-if="event.state !== 'ONDEMAND'"
                          >
                            {{ download.points }} pts.
                          </p>
                          <DownloadTracker
                            class="black py-2 px-4 ml-2"
                            :href="download.href"
                          >
                            <v-icon dark color="pink"> fas fa-download </v-icon>
                          </DownloadTracker>
                        </v-col>
                      </v-col>
                    </v-col>
                  </div>
                </v-col>

                <!-- Share your love section -->

                <v-col cols="12" class="pb-0 pr-0 pl-0 mt-4">
                  <div
                    class="pl-6 py-3 black text-left white--text"
                    style="font-size: 1.2rem"
                  >
                    <h3 class="pa-4 white--text">SHARE YOUR LOVE</h3>
                  </div>
                  <div class="py-6 d-flex white pr-4">
                    <v-col cols="12" class="pl-8 pr-8">
                      <h2 class="share-your-love-headline text-left pl-2 mb-4">
                        <b>#PANDORASMC2021</b>
                      </h2>
                      <v-col
                        cols="12"
                        class="agenda-container d-flex white pl-2 pt-1"
                      >
                        <iframe
                          allowfullscreen
                          id="wallsio-iframe"
                          src="https://my.walls.io/urbvv?nobackground=1&amp;show_header=0"
                          style="border: 0; height: 800px; width: 100%"
                          loading="lazy"
                          title="My social wall"
                        ></iframe>
                      </v-col>
                    </v-col>
                  </div>
                </v-col>
              </v-col>
            </v-row>
            <!-- Hidden questions -->

            <AppHiddenQuestion
              top="44%"
              right="5%"
              question="How many styling accents are featured in the Pandora Me reset?"
              :options="['Four', 'Six', 'Twelve']"
              answer="Six"
              :tracking-id="24"
            ></AppHiddenQuestion>

            <AppHiddenQuestion
              top="10%"
              right="17%"
              question="What are our four new values?"
              :options="[
                'Pride, Passion, Performance',
                'Dare, Care, Dream, Deliver',
                'People, Planet, Process ',
              ]"
              answer="Dare, Care, Dream, Deliver"
              :tracking-id="25"
            ></AppHiddenQuestion>

            <AppHiddenQuestion
              top="40%"
              left="0.5%"
              :tracking-id="36"
            ></AppHiddenQuestion>
          </v-container>
        </div>
      </v-sheet>
    </aspect-ratio-layout>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import ResponsiveLayout from "../layouts/ResponsiveLayout.vue";
import AspectRatioLayout from "../layouts/16by9Layout.vue";
import theatreMockData from "../assets/data/theatre-mock-data.json";
import downloadsMockData from "../assets/data/information-downloads-mock-data.json";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ResponsiveLayout,
    AspectRatioLayout,
  },

  data() {
    return {
      dialog: false,
      dialogFirst: false,
      dialogSecretSecond: false,
      dialogSecretThird: false,
      column: null,
      row: null,
      overlay: false,
      mockData: theatreMockData,
      downloadsMockData: downloadsMockData,
    };
  },

  async mounted() {
    await this.$store.dispatch("updatePointLeaderboard");
  },

  computed: {
    ...mapState(["pointLeaderboard", "downloads", "event"]),
    ...mapGetters(["me"]),
  },

  watch: {
    "$store.getters.isAuthenticated": async function (newValue, oldValue) {
      console.log("isAuthenticated change:", newValue, "oldValue:", oldValue);
      if (newValue) {
        await this.onLogin();
      }
    },
  },

  methods: {
    navigate() {
      const next = this.$route.query?.next;
      if (next) {
        this.$router.push(next);
      } else if (this.$store.getters.isAdmin) {
        this.$router.push({ name: "AdminHome" });
      } else {
        this.$router.push({ name: "Home" });
      }
    },
    async login() {
      this.error = false;
      try {
        await this.$store.dispatch("login", this.credentials);
      } catch (e) {
        this.error = true;
        console.debug("Login error:", e);
      }
    },
    async onLogin() {
      // Navigate
      this.navigate();
    },

    playIntroductionVideo() {
      const playArrow = document.getElementsByClassName(
        "play-btn-arrow-introduction"
      )[0];

      if (this.isPlaying == false && this.$session.get("firstTime", true)) {
        playArrow.classList.remove("fa-play");
        playArrow.classList.add("fa-pause");

        this.$refs.introductionVideo.play();
        this.isPlaying = true;
      } else {
        this.$refs.introductionVideo.pause();
        this.isPlaying = false;

        playArrow.classList.remove("fa-pause");

        playArrow.classList.add("fa-play");
      }
    },

    playWelcomeVideo() {
      const playArrow = document.getElementsByClassName("play-btn-arrow")[0];

      if (this.isPlaying == false) {
        playArrow.classList.remove("fa-play");
        playArrow.classList.add("fa-pause");

        this.$refs.welcomeVideo.play();
        this.isPlaying = true;
      } else {
        this.$refs.welcomeVideo.pause();
        this.isPlaying = false;

        playArrow.classList.remove("fa-pause");

        playArrow.classList.add("fa-play");
      }
    },

    toggleVideoOrCountDown() {
      var currentDate = new Date();
      var currentDateAsIso = currentDate.toISOString();

      if (this.testTime <= currentDateAsIso) {
        this.hideCountDown = true;
      }
    },
  },
};
</script>

<style scoped>
/*.main {
  background-image: url("https://vod.eventcdn.net/pandora/smc2021/Lounge_Bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@media screen and (min-width: 1020px) {
  .main {
    background-image: url("https://vod.eventcdn.net/pandora/smc2021/Lounge_Bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    backface-visibility: hidden;
  }
}*/

.v-application.livehouse-app .countdown-value {
  font-size: 5rem !important;
  color: var(--v-primary-base) !important;
  line-height: 5rem !important;
  font-weight: 700 !important;
}

@media screen and (max-width: 992px) {
  .v-application.livehouse-app .countdown-value {
    font-size: 3rem !important;
    line-height: 3.5rem !important;
  }
}

.countdown-with-pink-text {
  color: var(--pink) !important;
  font: var(--unnamed-font-style-normal) normal bold 80px/60px
    var(--unnamed-font-family-pan-text);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal bold 80px/60px Pan Text;
}

.countdown-headline {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) 22px/32px
    var(--unnamed-font-family-pan-text);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal normal 22px/32px Pan Text;
}

.unit {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-18) /
    var(--unnamed-line-spacing-22) var(--unnamed-font-family-pan-text);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--darker-grey);
  text-align: center;
  font: normal normal normal 18px/22px Pan Text;
}

.countdown-container {
  margin-top: 10.5rem;
}
</style>
